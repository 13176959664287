import React, { useEffect, useCallback, useMemo, useState } from 'react';
import ReactFlow, { 
    Controls, 
    Background, 
    useNodesState, 
    useEdgesState,
    Handle,
    Position,
    useReactFlow,
    MarkerType
} from 'reactflow';
import 'reactflow/dist/style.css';
import { flattenObject } from '../utils';

const typeColors = {
    dimension: '#4e79a7',
    color: '#f28e2c',
    spacing: '#e15759',
    borderRadius: '#76b7b2',
    borderWidth: '#59a14f',
    boxShadow: '#edc949',
    typography: '#af7aa1',
    opacity: '#e15759',
    fontFamilies: '#76b7b2',
    lineHeights: '#59a14f',
    letterSpacing: '#edc949',
    paragraphSpacing: '#af7aa1',
    fontWeights: '#ff9da7',
    fontSizes: '#9c755f',
    default: '#ff9da7'
};

const CustomNode = ({ data }) => (
    <div style={{
        padding: '10px',
        borderRadius: '5px',
        background: data.color || typeColors.default,
        color: '#fff',
        border: '1px solid #4a4a4a',
        width: 180,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
    }}>
        <Handle type="target" position={Position.Left} style={{ visibility: data.isCore ? 'visible' : 'hidden' }} />
        <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>{data.key}</div>
        <div style={{ fontSize: '12px', wordBreak: 'break-word' }}>{data.value}</div>
        <div style={{ fontSize: '10px', marginTop: '5px' }}>Type: {data.type}</div>
        <Handle type="source" position={Position.Right} style={{ visibility: data.isCore ? 'hidden' : 'visible' }} />
    </div>
);

const SectionTitleNode = ({ data }) => (
    <div style={{
        padding: '10px',
        borderRadius: '5px',
        background: '#2c2c2c',
        color: '#fff',
        border: '1px solid #4a4a4a',
        width: 180,
        textAlign: 'center',
        fontWeight: 'bold',
    }}>
        {data.label}
    </div>
);

const Visualizer = ({ coreData, mappingData, visibleTypes, searchTerm }) => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [isLayouting, setIsLayouting] = useState(false);
    const [filteredNodes, setFilteredNodes] = useState([]);
    const [filteredEdges, setFilteredEdges] = useState([]);
    const [error, setError] = useState(null);
    const { fitView } = useReactFlow();

    const createNodes = useCallback((obj, prefix = '', isCore = false, xOffset = 0) => {
        try {
            let nodes = [];
            let edges = [];
            const flattenedObj = flattenObject(obj);

            const groupedByType = Object.entries(flattenedObj).reduce((acc, [key, value]) => {
                const nodeType = typeof value === 'object' && value.type ? value.type : 'default';
                if (!acc[nodeType]) acc[nodeType] = [];
                acc[nodeType].push([key, value]);
                return acc;
            }, {});

            let yOffset = 0;
            Object.entries(groupedByType).forEach(([type, entries]) => {
                if (visibleTypes.includes(type)) {
                    // Add section title node
                    nodes.push({
                        id: `${prefix}${type}-title`,
                        type: 'sectionTitle',
                        position: { x: xOffset, y: yOffset },
                        data: { label: type.charAt(0).toUpperCase() + type.slice(1) }
                    });
                    yOffset += 100;

                    entries.forEach(([key, value], index) => {
                        const id = `${prefix}${key}`;
                        const nodeValue = typeof value === 'object' && value.value ? value.value : JSON.stringify(value);
                        const nodeColor = typeColors[type] || typeColors.default;
                        
                        nodes.push({
                            id,
                            type: 'custom',
                            position: { x: xOffset, y: yOffset + index * 100 },
                            data: { 
                                key,
                                value: nodeValue,
                                isCore,
                                fullPath: id,
                                type,
                                color: nodeColor
                            }
                        });

                        if (!isCore && typeof nodeValue === 'string' && nodeValue.includes('{') && nodeValue.includes('}')) {
                            const matches = nodeValue.match(/\{([^}]+)\}/g);
                            if (matches) {
                                matches.forEach(match => {
                                    const coreKey = match.slice(1, -1);
                                    edges.push({
                                        id: `${id}-${coreKey}`,
                                        source: id,
                                        target: `core.${coreKey}`,
                                        type: 'smoothstep',
                                        animated: true,
                                        style: { stroke: nodeColor },
                                        markerEnd: {
                                            type: MarkerType.ArrowClosed,
                                            color: nodeColor,
                                        },
                                    });
                                });
                            }
                        }
                    });
                    yOffset += entries.length * 100 + 50;
                }
            });

            return [nodes, edges];
        } catch (err) {
            console.error("Error in createNodes:", err);
            setError(`Error creating nodes: ${err.message}`);
            return [[], []];
        }
    }, [visibleTypes, setError]); // Add visibleTypes and setError to the dependency array

    const createVisualization = useCallback(() => {
        try {
            if (!coreData || !mappingData) {
                console.log("Missing data:", { coreData, mappingData });
                return;
            }
            console.log("Creating visualization", { coreData, mappingData, visibleTypes });
            const [mappingNodes, mappingEdges] = createNodes(mappingData, 'mapping.', false, 0);
            const [coreNodes, coreEdges] = createNodes(coreData, 'core.', true, 1000);
            
            const allNodes = [...mappingNodes, ...coreNodes];
            const allEdges = [...mappingEdges, ...coreEdges];
            
            console.log("Setting nodes and edges", { nodes: allNodes, edges: allEdges });
            setNodes(allNodes);
            setEdges(allEdges);
            
            setTimeout(() => {
                fitView({ padding: 0.2 });
            }, 0);
        } catch (err) {
            console.error("Error in createVisualization:", err);
            setError(`Error creating visualization: ${err.message}`);
        }
    }, [coreData, mappingData, createNodes, setNodes, setEdges, fitView]);

    useEffect(() => {
        setIsLayouting(true);
        setError(null);
        createVisualization();
        setIsLayouting(false);
    }, [createVisualization]);

    const onInit = useCallback((reactFlowInstance) => {
        console.log("ReactFlow initialized");
        reactFlowInstance.fitView({ padding: 0.2 });
    }, []);

    const nodeTypes = useMemo(() => ({ 
        custom: CustomNode,
        sectionTitle: SectionTitleNode
    }), []);

    const handleSearch = useCallback((searchTerm) => {
        if (!searchTerm) {
            setFilteredNodes(nodes);
            setFilteredEdges(edges);
            return;
        }

        const matchedNodes = nodes.filter(node => 
            node.type !== 'sectionTitle' &&
            (node.data.key.toLowerCase().includes(searchTerm.toLowerCase()) ||
            node.data.value.toLowerCase().includes(searchTerm.toLowerCase()))
        );

        const matchedNodeIds = new Set(matchedNodes.map(node => node.id));
        const connectedEdges = edges.filter(edge => 
            matchedNodeIds.has(edge.source) || matchedNodeIds.has(edge.target)
        );

        const connectedNodeIds = new Set([
            ...matchedNodeIds,
            ...connectedEdges.map(edge => edge.source),
            ...connectedEdges.map(edge => edge.target)
        ]);

        setFilteredNodes(nodes.filter(node => connectedNodeIds.has(node.id) || node.type === 'sectionTitle'));
        setFilteredEdges(connectedEdges);

        setTimeout(() => {
            fitView({ padding: 0.2 });
        }, 0);
    }, [nodes, edges, fitView]);

    useEffect(() => {
        handleSearch(searchTerm);
    }, [handleSearch, searchTerm]);

    return (
        <div style={{ height: '80vh', width: '100%' }}>
            {error ? (
                <div style={{ color: 'red', textAlign: 'center', paddingTop: '40vh' }}>
                    {error}
                </div>
            ) : isLayouting ? (
                <div style={{ color: 'white', textAlign: 'center', paddingTop: '40vh' }}>
                    Layouting nodes, please wait...
                </div>
            ) : filteredNodes.length > 0 ? (
                <ReactFlow
                    nodes={filteredNodes}
                    edges={filteredEdges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onInit={onInit}
                    nodeTypes={nodeTypes}
                    fitView
                    attributionPosition="bottom-left"
                    minZoom={0.1}
                    maxZoom={1.5}
                    style={{ background: '#1a1a1a' }}
                    connectionLineStyle={{ stroke: '#ffffff', strokeWidth: 2 }}
                    defaultEdgeOptions={{ type: 'smoothstep', animated: true }}
                >
                    <Controls style={{ background: '#242424', color: '#e0e0e0' }} />
                    <Background color="#2c2c2c" gap={16} />
                </ReactFlow>
            ) : (
                <div style={{ color: 'white', textAlign: 'center', paddingTop: '40vh' }}>
                    No nodes to display. Please check your input data and visible types.
                </div>
            )}
        </div>
    );
};

export default Visualizer;