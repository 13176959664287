import React, { useState, useEffect } from 'react';
import { ReactFlowProvider } from 'reactflow';
import Visualizer from './components/Visualizer';
import 'reactflow/dist/style.css';
import './App.css';
import ErrorBoundary from './components/ErrorBoundary';

const typeColors = {
    dimension: '#4e79a7',
    color: '#f28e2c',
    spacing: '#e15759',
    borderRadius: '#76b7b2',
    borderWidth: '#59a14f',
    boxShadow: '#edc949',
    typography: '#af7aa1',
    opacity: '#e15759',
    fontFamilies: '#76b7b2',
    lineHeights: '#59a14f',
    letterSpacing: '#edc949',
    paragraphSpacing: '#af7aa1',
    fontWeights: '#ff9da7',
    fontSizes: '#9c755f',
    default: '#ff9da7'
};

function App() {
    const [coreData, setCoreData] = useState(null);
    const [mappingData, setMappingData] = useState(null);
    const [error, setError] = useState('');
    const [tokenTypes, setTokenTypes] = useState([]);
    const [visibleTypes, setVisibleTypes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const handleFileUpload = (event, setData) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const json = JSON.parse(e.target.result);
                    setData(json);
                    setError('');
                } catch (err) {
                    setError('Invalid JSON file. Please check your input and try again.');
                    console.error('Error parsing JSON:', err);
                }
            };
            reader.onerror = (e) => {
                setError('Error reading file. Please try again.');
                console.error('File reading error:', e);
            };
            reader.readAsText(file);
        }
    };

    useEffect(() => {
        if (coreData && mappingData) {
            const types = new Set();
            const extractTypes = (obj) => {
                Object.values(obj).forEach(value => {
                    if (typeof value === 'object' && value.type) {
                        types.add(value.type);
                    }
                    if (typeof value === 'object' && !value.type) {
                        extractTypes(value);
                    }
                });
            };
            extractTypes(coreData);
            extractTypes(mappingData);
            const typeArray = Array.from(types);
            console.log("Updated data:", { coreData, mappingData, typeArray });
            setTokenTypes(typeArray);
            setVisibleTypes(typeArray);
        }
    }, [coreData, mappingData]);

    const handleTypeToggle = (type) => {
        setVisibleTypes(prev => 
            prev.includes(type) 
                ? prev.filter(t => t !== type)
                : [...prev, type]
        );
    };

    const loadSampleData = () => {
        const sampleCoreData = {
            "colors": {
                "primary": {
                    "value": "#0000FF",
                    "type": "color"
                },
                "secondary": {
                    "value": "#FF0000",
                    "type": "color"
                },
                "tertiary": {
                    "value": "#00FF00",
                    "type": "color"
                }
            },
            "spacing": {
                "small": {
                    "value": "4px",
                    "type": "dimension"
                },
                "medium": {
                    "value": "8px",
                    "type": "dimension"
                },
                "large": {
                    "value": "16px",
                    "type": "dimension"
                }
            },
            "typography": {
                "fontSizes": {
                    "small": {
                        "value": "12px",
                        "type": "fontSizes"
                    },
                    "medium": {
                        "value": "16px",
                        "type": "fontSizes"
                    },
                    "large": {
                        "value": "24px",
                        "type": "fontSizes"
                    }
                },
                "fontWeights": {
                    "regular": {
                        "value": "400",
                        "type": "fontWeights"
                    },
                    "bold": {
                        "value": "700",
                        "type": "fontWeights"
                    }
                },
                "lineHeights": {
                    "small": {
                        "value": "1.2",
                        "type": "lineHeights"
                    },
                    "medium": {
                        "value": "1.5",
                        "type": "lineHeights"
                    },
                    "large": {
                        "value": "2",
                        "type": "lineHeights"
                    }
                }
            },
            "borderRadius": {
                "small": {
                    "value": "2px",
                    "type": "borderRadius"
                },
                "medium": {
                    "value": "4px",
                    "type": "borderRadius"
                },
                "large": {
                    "value": "8px",
                    "type": "borderRadius"
                }
            },
            "opacity": {
                "low": {
                    "value": "0.3",
                    "type": "opacity"
                },
                "medium": {
                    "value": "0.5",
                    "type": "opacity"
                },
                "high": {
                    "value": "0.8",
                    "type": "opacity"
                }
            }
        };

        const sampleMappingData = {
            "button": {
                "primary": {
                    "backgroundColor": {
                        "value": "{colors.primary.value}",
                        "type": "color"
                    },
                    "color": {
                        "value": "#FFFFFF",
                        "type": "color"
                    },
                    "padding": {
                        "value": "{spacing.medium.value}",
                        "type": "dimension"
                    },
                    "borderRadius": {
                        "value": "{borderRadius.medium.value}",
                        "type": "borderRadius"
                    },
                    "fontSize": {
                        "value": "{typography.fontSizes.medium.value}",
                        "type": "fontSizes"
                    },
                    "fontWeight": {
                        "value": "{typography.fontWeights.bold.value}",
                        "type": "fontWeights"
                    },
                    "lineHeight": {
                        "value": "{typography.lineHeights.medium.value}",
                        "type": "lineHeights"
                    },
                    "opacity": {
                        "value": "{opacity.high.value}",
                        "type": "opacity"
                    }
                },
                "secondary": {
                    "backgroundColor": {
                        "value": "{colors.secondary.value}",
                        "type": "color"
                    },
                    "color": {
                        "value": "#FFFFFF",
                        "type": "color"
                    },
                    "padding": {
                        "value": "{spacing.small.value}",
                        "type": "dimension"
                    },
                    "borderRadius": {
                        "value": "{borderRadius.small.value}",
                        "type": "borderRadius"
                    },
                    "fontSize": {
                        "value": "{typography.fontSizes.small.value}",
                        "type": "fontSizes"
                    },
                    "fontWeight": {
                        "value": "{typography.fontWeights.regular.value}",
                        "type": "fontWeights"
                    },
                    "lineHeight": {
                        "value": "{typography.lineHeights.small.value}",
                        "type": "lineHeights"
                    },
                    "opacity": {
                        "value": "{opacity.medium.value}",
                        "type": "opacity"
                    }
                }
            }
        };

        setCoreData(sampleCoreData);
        setMappingData(sampleMappingData);
    };

    return (
        <div className="App">
            <header className="App-header">
                <h1>JSON Graph Visualizer</h1>
            </header>
            <div className="main-content">
                <aside className="sidebar">
                    <div className="file-inputs">
                        <div>
                            <label htmlFor="core-file">Upload Core JSON: </label>
                            <input
                                type="file"
                                id="core-file"
                                accept=".json"
                                onChange={(e) => handleFileUpload(e, setCoreData)}
                            />
                        </div>
                        <div>
                            <label htmlFor="mapping-file">Upload Mapping JSON: </label>
                            <input
                                type="file"
                                id="mapping-file"
                                accept=".json"
                                onChange={(e) => handleFileUpload(e, setMappingData)}
                            />
                        </div>
                    </div>
                    {error && <div className="error">{error}</div>}
                    <div className="search-container">
                        <input
                            type="text"
                            placeholder="Search nodes..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="search-input"
                        />
                    </div>
                    <div className="token-type-controls">
                        <h3>Token Types:</h3>
                        {tokenTypes.map(type => (
                            <label key={type} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                <input
                                    type="checkbox"
                                    checked={visibleTypes.includes(type)}
                                    onChange={() => handleTypeToggle(type)}
                                />
                                <span 
                                    style={{
                                        display: 'inline-block',
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: typeColors[type] || typeColors.default,
                                        marginRight: '8px',
                                        borderRadius: '4px'
                                    }}
                                ></span>
                                {type}
                            </label>
                        ))}
                    </div>
                    <div className="user-guide">
                        <h3>User Guide:</h3>
                        <ul>
                            <li>Upload both Core and Mapping JSON files to visualize the relationship.</li>
                            <li>Use the checkboxes above to show/hide different token types.</li>
                            <li>Each color represents a different token type.</li>
                            <li>Arrows show the relationships between tokens.</li>
                            <li>Use mouse wheel to zoom in/out, click and drag to pan the view.</li>
                        </ul>
                    </div>
                    <button onClick={loadSampleData}>Load Sample Data</button>
                </aside>
                <main>
                    <ErrorBoundary>
                        {coreData && mappingData ? (
                            <ReactFlowProvider>
                                <Visualizer 
                                    coreData={coreData} 
                                    mappingData={mappingData} 
                                    visibleTypes={visibleTypes}
                                    searchTerm={searchTerm}
                                />
                            </ReactFlowProvider>
                        ) : (
                            <div style={{ color: 'white', textAlign: 'center', paddingTop: '40vh' }}>
                                Please upload or load sample data to visualize.
                            </div>
                        )}
                        {console.log("Rendering Visualizer with:", { coreData, mappingData, visibleTypes, searchTerm })}
                    </ErrorBoundary>
                </main>
            </div>
        </div>
    );
}

export default App;